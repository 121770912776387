export default function JumpLinks(el, {
    ctaHandle,
    ctaArticle,
}) {
    const cta = el.querySelector(ctaHandle);
    const articles = cta.querySelectorAll(ctaArticle);

    articles.forEach((a, i) => {
        a.addEventListener('mouseenter', () => {
            const article = cta.querySelectorAll(ctaArticle);
            article[i].classList.add('active');
        })

        a.addEventListener('mouseleave', () => {
            const article = cta.querySelectorAll(ctaArticle);
            article[i].classList.remove('active');
        })
    });
}
