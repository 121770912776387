export default function Header(el, {
    sentinalHandle,
    fixedClass,
    searchActiveClass,
    menuActiveClass,
    navActiveClass,
    headerPrimaryContHandle,
    actions,
    events,
}) {
    const searchTrigger = el.querySelector('div > button');
    const searchForm = el.querySelector('form');
    const searchInput = searchForm ? searchForm.querySelector('[type="search"]') : null;
    const searchReset = searchForm ? searchForm.querySelector('[type="reset"]') : null;
    const menuLinks = el.querySelectorAll('nav > ul > li > a');
    const backButtons = el.querySelectorAll('nav > ul > li > a + div > button');
    const navTrigger = el.querySelector('nav + button');
    const anchorLinks = el.querySelectorAll('a[href*="#"]');

    const headerPrimaryCont = el.querySelector(headerPrimaryContHandle);

    const sentinal = el.querySelector(sentinalHandle);
    const handleFix = ([{ isIntersecting }]) => {
        el.classList.toggle(fixedClass, !isIntersecting);
    };
    const observer = new IntersectionObserver(handleFix);
    const handleEscape = ({ keyCode }) => {
        // Only care about escape key
        if (keyCode !== 27) return;

        el.classList.remove(searchActiveClass, menuActiveClass, navActiveClass);
        searchInput.blur();
        menuLinks.forEach(ml => { ml.closest('li').classList.remove(menuActiveClass); });
        document.removeEventListener('keyup', handleEscape);
        events.emit(actions.unlockScroll);
    };

    if (searchTrigger && searchInput && searchReset) {
        searchTrigger.onclick = () => {
            el.classList.add(searchActiveClass);
            searchInput.focus();
            document.addEventListener('keyup', handleEscape);
        };

        searchReset.onclick = () => {
            el.classList.remove(searchActiveClass);
            searchInput.blur();
            document.addEventListener('keyup', handleEscape);
        };
    }

    menuLinks.forEach(ml => {
        ml.onclick = e => {
            const li = e.target.closest('li');
            const div = li.querySelector('div');

            if (div) {
                e.preventDefault();

                [...menuLinks]
                    .filter(mll => mll !== ml)
                    .forEach(mll => { mll.closest('li').classList.remove(menuActiveClass); });
                const open = ml.closest('li').classList.toggle(
                    menuActiveClass,
                    !ml.closest('li').classList.contains(menuActiveClass),
                );

                if (el.classList.toggle(menuActiveClass, open)) {
                    events.emit(actions.lockScroll);
                    document.addEventListener('keyup', handleEscape);
                } else {
                    events.emit(actions.unlockScroll);
                }
            }
        };
    });

    anchorLinks.forEach(l => {
        l.addEventListener('click', e => {
            const { target } = e;
            const id = target.getAttribute('href').split('#')[1];
            const anchor = document.getElementById(id);

            // If we are on the page with the anchor link
            if (anchor) {
                e.preventDefault();

                window.scrollTo({
                    top: anchor.getBoundingClientRect().top
                        + window.scrollY
                        - headerPrimaryCont.offsetHeight
                        + 2,
                    behavior: 'smooth',
                });

                // Close all menus before scrolling
                el.classList.remove(searchActiveClass, menuActiveClass, navActiveClass);
                menuLinks.forEach(ml => { ml.closest('li').classList.remove(menuActiveClass); });
                events.emit(actions.unlockScroll);
            }
        });
    });

    backButtons.forEach(bb => {
        bb.onclick = () => {
            bb.closest('li').classList.remove(menuActiveClass);
            el.classList.remove(menuActiveClass);
        };
    });

    navTrigger.onclick = () => {
        if (el.classList.toggle(navActiveClass)) {
            events.emit(actions.lockScroll);
        } else {
            events.emit(actions.unlockScroll);
        }

        el.classList.remove(menuActiveClass);
        [...menuLinks].forEach(mll => {
            mll.closest('li').classList.remove(menuActiveClass);
        });
        document.addEventListener('keyup', handleEscape);
    };

    observer.observe(sentinal);

    // Offset anchors by fixed header height on page load
    // anchor.offsetTop is only correct after fonts load
    document.fonts.ready.then(() => {
        if (window.location.hash !== '') {
            const anchor = document.querySelector(window.location.hash);

            if (anchor) {
                const top = anchor.offsetTop - (headerPrimaryCont.offsetHeight * 1.2);

                window.scrollTo({ top, behavior: 'smooth' });
            }
        }
    });
}
