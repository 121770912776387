import Mark from 'mark.js';
import parents from '../utilities/parents';
import debounce from '../utilities/debounce';

export default function DocumentsListing(el, {
    anchorHandle,
    overlayClass,
    actions,
    events,
}) {
    const menuToggle = el.querySelector('nav > button');
    const menuList = el.querySelector('nav > ul');
    const documentType = menuList.querySelectorAll('li > a');
    const searchForm = el.querySelector('form[method="get"]');
    const searchInput = searchForm.querySelector('input[type="search"]');
    const [resultReset, emptyReset] = el.querySelectorAll('button[type="reset"]');
    const resultMessage = resultReset.closest('div');
    const resultTag = resultMessage.querySelector('em');
    const emptyMessage = emptyReset.closest('header');
    const emptyTag = emptyMessage.querySelector('em');
    const lists = el.querySelectorAll('li.list');
    const accordions = el.querySelectorAll('details');
    const documents = el.querySelectorAll('li.document');
    const marks = [...documents].map(d => new Mark(d));

    function handleLink(e) {
        const { target } = e;

        if (!target.matches(anchorHandle)) return;

        e.preventDefault();

        const id = target.getAttribute('href').split('#')[1];
        const list = document.getElementById(id).offsetTop;

        documentType.forEach(d => {
            if (d.classList.contains('documents-listing__anchor--active')) {
                d.classList.remove('documents-listing__anchor--active');
            }
            target.classList.add('documents-listing__anchor--active');
        });

        events.emit(actions.closeModal);
        if (list) {
            window.scrollTo({
                top: list - 140,
                behavior: 'smooth',
            });
        }
        // Use event delegation to body element since links are dynamically loaded into the modal
        document.body.removeEventListener('click', handleLink);
    }

    menuToggle.onclick = () => {
        events.emit(actions.loadModal, {
            markup: `<div class="${overlayClass}">
                ${menuToggle.innerHTML}${menuList.outerHTML}
            </div>`,
            type: 'list',
        });
        // Use event delegation to body element since links are dynamically loaded into the modal
        document.body.addEventListener('click', handleLink);
    };

    documentType.forEach(d => {
        d.addEventListener('click', handleLink);
    });

    searchInput.onkeyup = debounce(() => {
        const { value } = searchInput;

        if (value === '') {
            resultMessage.style.display = 'none';
            emptyMessage.style.display = 'none';
            lists.forEach(l => {
                l.style.display = 'block';
            });
            accordions.forEach(a => {
                a.style.display = 'block';
                a.removeAttribute('open');
            });
            documents.forEach(d => {
                d.style.display = 'flex';
            });
            marks.forEach(m => {
                m.unmark();
            });

            return;
        }

        const matched = [];
        const nomatched = [];

        resultMessage.style.display = 'none';
        emptyMessage.style.display = 'none';
        lists.forEach(l => {
            l.style.display = 'none';
        });
        accordions.forEach(a => {
            a.style.display = 'none';
            a.removeAttribute('open');
        });
        documents.forEach((d, i) => {
            d.style.display = 'none';
            marks[i].unmark({
                done: () => {
                    marks[i].mark(value, {
                        done: count => {
                            if (count) {
                                matched.push(d);
                            } else {
                                nomatched.push(d);
                            }

                            if (nomatched.length === documents.length) {
                                emptyTag.innerHTML = `“<strong>${value}</strong>”`;
                                emptyMessage.style.display = 'block';

                                return;
                            }

                            if (matched.length + nomatched.length === documents.length) {
                                resultTag.innerHTML = `${matched.length} ${matched.length === 1 ? 'result' : 'results'} for: <strong>“${value}”</strong>`;
                                resultMessage.style.display = 'block';

                                matched.forEach(m => {
                                    m.style.display = 'flex';
                                    parents(m, 'details').forEach(a => {
                                        a.style.display = 'block';
                                        a.setAttribute('open', '');
                                    });
                                    parents(m, 'li.list').forEach(l => {
                                        l.style.display = 'block';
                                    });
                                });
                            }
                        },
                    });
                },
            });
        });
    }, 500);
    [resultReset, emptyReset].forEach(r => {
        r.onclick = () => {
            searchInput.value = '';
            searchInput.onkeyup();
        };
    });
}
