import Swiper, { Navigation } from 'swiper';

export default function VideoCarousel(el, {
    slidesHandle,
}) {
    const slides = el.querySelectorAll(slidesHandle);
    const swiperHandle = el.querySelector('.swiper');

    const next = el.querySelector('.swiper-button-next');
    const prev = el.querySelector('.swiper-button-prev');

    const swiper = new Swiper(swiperHandle, { // eslint-disable-line
        modules: [Navigation],
        navigation: {
            nextEl: next,
            prevEl: prev,
        },
        allowTouchMove: false,
        slidesPerView: 1.25,
        spaceBetween: 30,
        speed: 600,
        breakpoints: {
            1440: {
                slidesPerView: 2.25,
            },
        },
    });

    slides.forEach(s => {
        const video = s.querySelector('video');

        if (video) {
            video.addEventListener('mouseenter', () => {
                video.play();
            });

            video.addEventListener('mouseleave', () => {
                video.pause();
            });
        }
    });
}
