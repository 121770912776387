export default function Modal(el, {
    contentHandle,
    closeHandle,
    activeClass,
    actions,
    events,
    refresh,
}) {
    const content = el.querySelector(contentHandle);
    const close = el.querySelector(closeHandle);

    // Event handler functions
    function handleKeyup(e) {
        // Only care about escape key
        if (e.keyCode !== 27) return;

        events.emit(actions.closeModal);
    }

    function handleOpenModal() {
        events.emit(actions.lockScroll);
        el.classList.add(activeClass);

        document.addEventListener('keyup', handleKeyup);
    }

    function handleCloseModal() {
        events.emit(actions.unlockScroll);
        el.classList.remove(activeClass);
        content.innerHTML = '';

        document.removeEventListener('keyup', handleKeyup);
    }

    function handleLoadModal(e) {
        const {
            markup,
            type = 'video',
        } = e.detail;

        el.setAttribute('data-type', type);
        content.innerHTML = markup;
        refresh(content);
        handleOpenModal();
    }

    function handleClick() {
        events.emit(actions.closeModal);
    }

    // Add event listeners
    events.on(actions.openModal, handleOpenModal);
    events.on(actions.closeModal, handleCloseModal);
    events.on(actions.loadModal, handleLoadModal);
    close.addEventListener('click', handleClick);
}
