export default function EntryShareButton(el, {
    actions,
    events,
}) {
    const button = el.querySelector('button');

    button.onclick = () => {
        // Load share form in modal
        events.emit(actions.loadModal, {
            markup: button.nextElementSibling.innerHTML,
            type: 'share-form',
        });
    };
}
