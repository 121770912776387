import Swiper, { Navigation } from 'swiper';

export default function CardDetailCarousel(el, {
    carouselHandle,
}) {
    const cont = el.querySelector(carouselHandle);
    const swiperHandle = cont.querySelector('.swiper');

    const next = el.querySelector('.swiper-button-next');
    const prev = el.querySelector('.swiper-button-prev');

    new Swiper(swiperHandle, { // eslint-disable-line no-new
        modules: [Navigation],
        navigation: {
            nextEl: next,
            prevEl: prev,
        },
        allowTouchMove: false,
        slidesPerView: 1.2,
        spaceBetween: 36,
        slidesOffsetBefore: 33,
        slidesOffsetAfter: 33,
        speed: 600,
        breakpoints: {
            992: {
                slidesPerView: 2,
                spaceBetween: 50,
                slidesOffsetBefore: 480,
                slidesOffsetAfter: 60,
            },
            1120: {
                slidesPerView: 2,
                spaceBetween: 50,
                slidesOffsetBefore: 480,
                slidesOffsetAfter: 60,
            },
            1200: {
                slidesPerView: 1.85,
                spaceBetween: 50,
                slidesOffsetBefore: 480,
                slidesOffsetAfter: 60,
            },
            1280: {
                slidesPerView: 1.75,
                spaceBetween: 50,
                slidesOffsetBefore: 480,
                slidesOffsetAfter: 60,
            },
            1441: {
                slidesPerView: 1.75,
                spaceBetween: 66,
                slidesOffsetBefore: 520,
                slidesOffsetAfter: 60,
            },
            1600: {
                slidesPerView: 1.55,
                spaceBetween: 66,
                slidesOffsetBefore: 520,
                slidesOffsetAfter: 60,
            },
        },
    });
}
