import Mark from 'mark.js';
import debounce from '../utilities/debounce';

export default function PriceSheets(el) {
    const searchForm = el.querySelector('form[method="get"]');
    const searchInput = searchForm.querySelector('input[type="search"]');
    const [resultReset, emptyReset] = el.querySelectorAll('button[type="reset"]');
    const resultMessage = resultReset.closest('p');
    const resultTag = resultMessage.querySelector('em');
    const emptyMessage = emptyReset.closest('header');
    const emptyTag = emptyMessage.querySelector('em');
    const accordions = el.querySelectorAll('details');
    const marks = [...accordions].map(s => new Mark(s));

    searchInput.onkeyup = debounce(() => {
        const { value } = searchInput;

        if (value === '') {
            resultMessage.style.display = 'none';
            emptyMessage.style.display = 'none';
            accordions.forEach(a => {
                a.style.display = 'block';
                a.removeAttribute('open');
            });
            marks.forEach(m => {
                m.unmark();
            });

            return;
        }

        let resultsCount = 0;
        const matched = [];
        const nomatched = [];

        resultMessage.style.display = 'none';
        emptyMessage.style.display = 'none';
        accordions.forEach((a, i) => {
            a.style.display = 'none';
            a.removeAttribute('open');
            marks[i].unmark({
                done: () => {
                    marks[i].mark(value, {
                        done: count => {
                            if (count) {
                                resultsCount += count;
                                matched.push(a);
                            } else {
                                nomatched.push(a);
                            }

                            if (nomatched.length === accordions.length) {
                                emptyTag.innerHTML = `“<strong>${value}</strong>”`;
                                emptyMessage.style.display = 'block';

                                return;
                            }

                            if (matched.length + nomatched.length === accordions.length) {
                                resultTag.innerHTML = `${resultsCount} ${resultsCount === 1 ? 'result' : 'results'} for: <strong>“${value}”</strong>`;
                                resultMessage.style.display = 'block';

                                matched.forEach(m => {
                                    m.style.display = 'block';
                                    m.setAttribute('open', '');
                                });
                            }
                        },
                    });
                },
            });
        });
    }, 500);
    [resultReset, emptyReset].forEach(r => {
        r.onclick = () => {
            searchInput.value = '';
            searchInput.onkeyup();
        };
    });
}
