import Swiper, { Navigation, Pagination } from 'swiper';

export default function MediaCarousel(el, {
    quotesHandle,
}) {
    const cont = el.querySelector(quotesHandle);
    const swiperHandle = cont.querySelector('.swiper');
    const next = cont.querySelector('.swiper-button-next');
    const prev = cont.querySelector('.swiper-button-prev');
    const pagination = cont.querySelector('.swiper-pagination');

    new Swiper(swiperHandle, { // eslint-disable-line no-new
        modules: [Navigation, Pagination],
        navigation: {
            nextEl: next,
            prevEl: prev,
        },
        allowTouchMove: true,
        pagination: {
            el: pagination,
        },
        loop: true,
        speed: 0,
        breakpoints: {
            992: {
                allowTouchMove: false,
            }
        },
    });
}