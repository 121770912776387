import Swiper, { Navigation } from 'swiper';

export default function Timeline(el, {
    carouselContHandle,
    controlsHandle,
}) {
    const cont = el.querySelector(carouselContHandle);

    const controls = el.querySelector(controlsHandle);
    const next = controls.querySelector('.swiper-button-next');
    const prev = controls.querySelector('.swiper-button-prev');

    new Swiper(cont, { // eslint-disable-line no-new
        modules: [Navigation],
        navigation: {
            nextEl: next,
            prevEl: prev,
        },
        allowTouchMove: false,
        slidesPerView: 'auto',
        centeredSlides: true,
    });
}
