export default function ScheduleForm(el, {
    name,
    conditionalName,
    conditionalValue,
    conditionalRequired = false,
    requiredClass,
    actions,
    events,
}) {
    const input = el.querySelector(`[name="${name}"]`);
    const form = el.closest('form');
    const targets = conditionalName
        ? form.querySelectorAll(`[name="${conditionalName}"]`)
        : [];
    const error = el.querySelector('p');

    function toggleVisibility(visible) {
        // Toggle input element display
        el.style.display = visible ? 'block' : 'none';
        // Toggle required state of input if input is visible and conditionally required
        input.required = visible && conditionalRequired;
        el.classList.toggle(requiredClass, input.required);
        // Reset value of input and trigger change to propogate event in
        // case this input is the target for another input
        if (!visible) {
            input.value = '';
            input.dispatchEvent(new Event('change'));
        }
    }

    function handleSetInputValue(e) {
        const { name: n, value } = e.detail;
        if (n === name) {
            input.value = value;
        }
    }

    function testConditional(val, match) {
        switch (typeof match) {
            case 'string':
                return val === match;
            case 'object':
                return match.includes(val);
            default:
                return false;
        }
    }

    function handleTargetChange(e) {
        const { value } = e.currentTarget;
        const show = testConditional(value, conditionalValue);
        if (!show) {
            toggleVisibility(show);
            return;
        }
        function cb() {
            toggleVisibility(show);
        }
        cb();
    }

    events.on(actions.setInputValue, handleSetInputValue);
    targets.forEach(target => {
        target.addEventListener('change', handleTargetChange);
    });

    events.on(actions.showFieldError, ({ detail }) => {
        if (detail.name === name) {
            error.textContent = detail.errors.join(', ');
        }
    });

    // Initalize conditional formatting
    if (conditionalName) {
        const formData = new FormData(form);
        toggleVisibility(testConditional(formData.get(conditionalName), conditionalValue));
    }
}
