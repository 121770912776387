import Swiper, { Navigation } from 'swiper';

export default function FeaturedMarkets(el, {
    carouselHandle,
}) {
    const cont = el.querySelector(carouselHandle);
    const swiperHandle = cont.querySelector('.swiper');
    const next = cont.querySelector('.swiper-button-next');
    const prev = cont.querySelector('.swiper-button-prev');

    const swiper = new Swiper(swiperHandle, { // eslint-disable-line no-new
        modules: [Navigation],
        navigation: {
            nextEl: next,
            prevEl: prev,
        },
        allowTouchMove: false,
        slidesPerView: 1.25,
        slidesPerGroup: 1,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        spaceBetween: 35,
        breakpoints: {
            768: {
                slidesPerGroup: 1,
                slidesPerView: 2.25,
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0,
                spaceBetween: 35,
            },
            992: {
                slidesPerGroup: 1,
                slidesPerView: 2.5,
                slidesOffsetBefore: 110,
                slidesOffsetAfter: 60,
                spaceBetween: 50,
            },
            1280: {
                slidesPerGroup: 1,
                slidesPerView: 3.5,
                slidesOffsetBefore: 115,
                slidesOffsetAfter: 65,
            },
            1440: {
                slidesPerGroup: 1,
                slidesPerView: 3.5,
                slidesOffsetBefore: 130,
                slidesOffsetAfter: 80,
            },
            1920: {
                slidesPerView: 3.5,
                slidesOffsetBefore: 130,
                slidesOffsetAfter: 80,
            }
        }
    });

    function handleSlideClick() {
        const mq = window.matchMedia("(max-width: 767px)");
        if (!mq.matches) {
            if (this === prev) {
              swiper.slidePrev(600);
            } else {
              swiper.slideNext(600);
            }
        }
    }
    
    prev.addEventListener('click', handleSlideClick);
    next.addEventListener('click', handleSlideClick);
}