// Framework
import pop from './components';

// Components
import BackgroundImageSpotlight from './components/background-image-spotlight';
import CardDetailCarousel from './components/card-detail-carousel';
import DocumentsListing from './components/documents-listing';
import EntryShareButton from './components/entry-share-button';
import EntryShareForm from './components/entry-share-form';
import FeaturedForm from './components/featured-form';
import FeaturedMarkets from './components/featured-markets';
import FeaturedPages from './components/featured-pages';
import FormField from './components/form-field';
import Header from './components/header';
import JumpLinks from './components/jump-links';
import LocationMap from './components/location-map';
import Main from './components/main';
import Modal from './components/modal';
import PriceSheets from './components/price-sheets';
import PrimaryHero from './components/primary-hero';
import QuotesCarousel from './components/quotes-carousel';
import SearchResults from './components/search-results';
import SlidesCarousel from './components/slides-carousel';
import Timeline from './components/timeline';
import Tooltip from './components/tooltip';
import Video from './components/video';
import VideoCarousel from './components/video-carousel';
// import VideoCard from './components/video-card';

// Define map of component handles to component classes
const classMap = {
    'background-image-spotlight': BackgroundImageSpotlight,
    'card-detail-carousel': CardDetailCarousel,
    'documents-listing': DocumentsListing,
    'entry-share-button': EntryShareButton,
    'entry-share-form': EntryShareForm,
    'featured-form': FeaturedForm,
    'featured-markets': FeaturedMarkets,
    'featured-pages': FeaturedPages,
    'form-field': FormField,
    header: Header,
    'jump-links': JumpLinks,
    'location-map': LocationMap,
    main: Main,
    modal: Modal,
    'price-sheets': PriceSheets,
    'primary-hero': PrimaryHero,
    'quotes-carousel': QuotesCarousel,
    'search-results': SearchResults,
    'slides-carousel': SlidesCarousel,
    timeline: Timeline,
    tooltip: Tooltip,
    video: Video,
    'video-carousel': VideoCarousel,
    // 'video-card': VideoCard,
};

// Define all actions/commands that components pub/sub
const actions = {
    // Action events
    lockScroll: 'lock-scroll',
    unlockScroll: 'unlock-scroll',
    showOverlay: 'show-overlay',
    hideOverlay: 'hide-overlay',
    openModal: 'open-modal',
    closeModal: 'close-modal',
    loadModal: 'load-modal',
    resetPage: 'resetPage',
    showFieldError: 'show-field-error',
};

// Event handler functions
function handleDOMConentLoaded() {
    function cb() {
        // Do anything after components are created.
    }

    pop({ classMap, actions, cb });
}

// Add event listeners
document.addEventListener('DOMContentLoaded', handleDOMConentLoaded);
