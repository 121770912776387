export default function parents(elem, selector) {
    const ps = [];

    for (; elem && elem !== document; elem = elem.parentNode) {
        if (selector) {
            if (elem.matches(selector)) {
                ps.push(elem);
            }

            continue; // eslint-disable-line no-continue
        }
        ps.push(elem);
    }

    return ps;
}
