import { loadScript } from '../utilities/resources';

export default function FeaturedForm(el, {
    formHandle,
    siteKey,
    actions,
    events,
}) {
    const urlParams = new URLSearchParams(window.location.search);
    const success = urlParams.get('success');

    if (success) {
        window.scrollTo({
            top: el.getBoundingClientRect().top + window.scrollY - 50,
            behavior: 'smooth',
        });

        return;
    }

    const form = el.querySelector('form');
    const formName = encodeURIComponent(formHandle);

    if (form) {
        const submit = form.querySelector('button[type="submit"]');

        submit.removeAttribute('disabled');

        loadScript(`https://www.google.com/recaptcha/api.js?render=${siteKey}`, () => {
            const grc = grecaptcha; // eslint-disable-line no-undef

            form.onsubmit = e => {
                e.preventDefault();

                submit.setAttribute('disabled', 'true');

                const body = new FormData(form);

                grc.ready(() => {
                    grc.execute(siteKey, { action: 'submit' }).then(token => {
                        body.append('token', token);

                        fetch('/', {
                            method: 'POST',
                            headers: {
                                Accept: 'application/json',
                            },
                            body,
                        })
                            .then(res => res.json().then(json => ({
                                status: res.status,
                                ...json,
                            })))
                            .then(({
                                status,
                                message = '',
                                errors = {},
                            }) => {
                                // Reset all field errros to empty
                                Array.from(body.keys()).map(name => name.replace('[]', '')).forEach(name => {
                                    events.emit(actions.showFieldError, { name, errors: [] });
                                });
                                submit.removeAttribute('disabled');

                                if (status === 500) {
                                    window.alert(message); // eslint-disable-line no-alert

                                    return;
                                }

                                if (status === 400) {
                                    Object.entries(errors).forEach(([name, errs]) => {
                                        events.emit(actions.showFieldError, { name, errors: errs });
                                    });

                                    return;
                                }

                                if (status === 200) {
                                    form.remove();
                                    window.location.href = `${window.location.pathname}?form=${formName}&success=true`;
                                }
                            });
                    });
                });
            };
        });
    }
}
