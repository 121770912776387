export default function Tooltip(el, {
    contHandle,
    iconHandle,
    itemsHandle,
    activeClass,
}) {
    const cont = el.querySelector(contHandle);
    const icon = cont.querySelector(iconHandle);
    const items = cont.querySelector(itemsHandle);
    let tooltipVisible = false;

    function handleHideTooltip() {
        if (tooltipVisible) {
            items.classList.remove(activeClass);
            tooltipVisible = false;
            document.body.removeEventListener('click', handleHideTooltip);
        }
    }

    function handleShowTooltip() {
        if (!tooltipVisible) {
            items.classList.add(activeClass);
            tooltipVisible = true;
            document.body.addEventListener('click', handleHideTooltip);
        }
    }

    function handleIconClick(e) {
        e.stopPropagation();
        handleShowTooltip();
    }

    icon.addEventListener('mouseenter', handleShowTooltip);
    icon.addEventListener('click', handleIconClick);
    items.addEventListener('click', handleIconClick);
    window.addEventListener('scroll', handleHideTooltip);
}
