import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default function Main(el, {
    lockedClass,
    actions,
    events,
}) {
    const anchorLinks = el.querySelectorAll('a[href^="#"]');
    const videoLinks = el.querySelectorAll('a[href*="youtube"][data-video-id], a[href*="youtu.be"][data-video-id]');

    // Event handler functions
    function handleLockScroll() {
        document.body.classList.add(lockedClass);
        disableBodyScroll();
    }
    function handleUnlockScroll() {
        document.body.classList.remove(lockedClass);
        clearAllBodyScrollLocks();
    }
    function handleVideoClick(e) {
        e.preventDefault();

        const { videoId } = e.currentTarget.dataset;
        const videoSrc = `https://www.youtube.com/embed/${videoId}?autoplay=1`;
        const markup = `<div><iframe src="${videoSrc}" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe></div>`;

        events.emit(actions.loadModal, { markup });
    }

    anchorLinks.forEach(link => {
        link.addEventListener('click', e => {
            e.preventDefault();

            const targetId = link.getAttribute('href').substring(1);
            const targetElement = document.getElementById(targetId);
            const header = document.querySelector('.header__primary-cont').offsetHeight;
            const headerHeight = header;

            if (targetElement) {
                const offsetPosition = targetElement.offsetTop - headerHeight;
                window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
            }
        });
    });


    // Add event listeners
    events.on(actions.lockScroll, handleLockScroll);
    events.on(actions.unlockScroll, handleUnlockScroll);
    videoLinks.forEach(link => {
        link.addEventListener('click', handleVideoClick);
    });
}
