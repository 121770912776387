export default function SearchResults(el, {
    refresh,
}) {
    const count = el.querySelector('section > nav > p > span > em');
    const results = el.querySelector('section > ul');
    const more = el.querySelector('section > div > a');

    more.onclick = e => {
        e.preventDefault();

        const { search } = new URL(more.getAttribute('href'));

        more.setAttribute('disabled', 'true');

        fetch(`/json/search-results${search}`, {
            method: 'get',
            headers: {
                Accept: 'application/json',
            },
        })
            .then(res => res.json().then(json => ({
                status: res.status,
                ...json,
            })))
            .then(({
                status,
                last,
                nextUrl,
                markup,
            }) => {
                more.removeAttribute('disabled');

                if (status !== 200) {
                    return;
                }

                count.innerHTML = last;
                more.setAttribute('href', nextUrl || '');
                results.insertAdjacentHTML('beforeend', markup);
                refresh(results);
            });
    };
}