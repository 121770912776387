export default function FeaturedPages(el, {
    ctaCardHandle,
    ctaCardOverlay,
    ctaCardFullHeight,
    ctaCardContent,
}) {
    const cta = el.querySelector(ctaCardHandle);
    const overlay = cta.querySelector(ctaCardOverlay);
    const fullHeight = cta.querySelector(ctaCardFullHeight);
    const headline = cta.querySelector('h4');
    const content = cta.querySelector(ctaCardContent);
    const transformHeight = content.offsetHeight;
    const mq = window.matchMedia("(max-width: 991px");

    function handleResize() {
        if (!mq.matches) {
            const height = content.offsetHeight;
            headline.style.transform = `translateY(${height}px)`
        } else {
            headline.style.transform = 'translateY(0px)';
        }
    }

    function handleHover() {
        if (!mq.matches) {
            overlay.classList.add('active');
            fullHeight.classList.add('active');
            content.classList.add('show');

            headline.style.transform = 'translateY(0)';
        }
    }

    function handleStatic() {
        if (!mq.matches) {
            overlay.classList.remove('active');
            fullHeight.classList.remove('active');
            content.classList.remove('show');
        }

        handleResize();
    }

    cta.addEventListener('mouseenter', handleHover);
    cta.addEventListener('mouseleave', handleStatic);

    window.addEventListener('resize', handleResize);
    window.addEventListener('load', () => {
        headline.style.transform = 'translateY(' + transformHeight + 'px)';

        if (mq.matches) {
            headline.style.transform = 'translateY(0px)';
        }
    });
}
