export default function LocationMap(el, {
    isActiveClass,
    mainContHandle,
    productsContHandle,
    productsListContentHandle,
    closeButtonHandle,
}) {
    const cont = el.querySelector(mainContHandle);
    const buttons = cont.querySelector(productsContHandle);
    const button = buttons.querySelectorAll('ul > li > button');
    const content = cont.querySelector(productsListContentHandle);
    const info = content.querySelectorAll('li');
    const closeButton = cont.querySelector(closeButtonHandle);

    const buttonsMap = document.querySelectorAll('[data-target]');
    const images = document.querySelectorAll('img.image');
    const firstImage = images[0];
    
    buttonsMap.forEach(b => {
        b.addEventListener('click', (e) => { // Add 'event' as a parameter
            const target = e.target.getAttribute('data-target');
            const targetImage = document.getElementById(target);
            
            images.forEach(i => {
                i.style.display = 'none';
            });
            targetImage.style.display = 'block';
        });
    });
    

    button.forEach(b => {
        b.addEventListener('click', () => {
            const buttonData = b.getAttribute('data-content');
            const contentItem = content.querySelector(`#${buttonData}`);

            cont.classList.add('no-padding-bottom');
            button.forEach(btn => { btn.classList.toggle(isActiveClass, btn === b); });
            info.forEach(item => { item.style.display = 'none'; });
            contentItem.style.display = 'flex';
            closeButton.style.display = 'flex';
        });
    });

    closeButton.addEventListener('click', () => {
        button.forEach(b => { b.classList.remove(isActiveClass); });
        info.forEach(i => { i.style.display = 'none'; });
        closeButton.style.display = 'none';
        images.forEach(i => {
            i.style.display = 'none';
        });
        firstImage.style.display = 'block';
        cont.classList.remove('no-padding-bottom');
    });
}
