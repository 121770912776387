import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function BackgroundImageSpotlight(el, {
    spotlightHandle,
    spotlightImage,
    spotlightReverse,
}) {
    gsap.registerPlugin(ScrollTrigger);

    function handleAnimate() {
        const cont = el.querySelector(spotlightHandle);
        const reverse = cont.querySelectorAll(spotlightReverse);
        const image = cont.querySelectorAll(spotlightImage);

        image.forEach((animate) => {
            let mm = gsap.matchMedia();
            let animateCircle = gsap.timeline({
                scrollTrigger: {
                    trigger: animate,
                    start: 'top center',
                    end: 'bottom center',
                    // markers: true,
                    scrub: true,
                }
            });
            
            if (reverse.length > 0) {
                mm.add("(max-width: 767px)", () => {
                    animateCircle.fromTo(
                        animate,
                        {
                            clipPath: 'circle(100px at calc(0% - 50px) 35%)',
                        },
                        {
                            clipPath: 'circle(400px at calc(0% - 300px) 35%)',
                        }
                    );
                });

                mm.add("(min-width: 768px) and (max-width: 991px)", () => {
                    animateCircle.fromTo(
                        animate,
                        {
                            clipPath: 'circle(100px at calc(0% - 50px) 35%)',
                        },
                        {
                            clipPath: 'circle(400px at calc(0% - 200px) 35%)',
                        }
                    );
                });

                mm.add("(min-width: 992px)", () => {
                    animateCircle.fromTo(
                        animate,
                        {
                            clipPath: 'circle(100px at 25% 50%)',
                        },
                        {
                            clipPath: 'circle(650px at 25% 50%)',
                        }
                    );
                });
            } else {
                mm.add("(max-width: 767px)", () => {
                    animateCircle.fromTo(
                        animate,
                        {
                            clipPath: 'circle(100px at calc(100% + 50px) 35%)',
                        },
                        {
                            clipPath: 'circle(400px at calc(100% + 300px) 35%)',
                        }
                    );
                });

                mm.add("(min-width: 768px) and (max-width: 991px)", () => {
                    animateCircle.fromTo(
                        animate,
                        {
                            clipPath: 'circle(100px at calc(100% + 50px) 35%)',
                        },
                        {
                            clipPath: 'circle(400px at calc(100% + 200px) 35%)',
                        }
                    );
                });

                mm.add("(min-width: 992px)", () => {
                    animateCircle.fromTo(
                        animate,
                        {
                            clipPath: 'circle(100px at 75% 50%)',
                        },
                        {
                            clipPath: 'circle(650px at 75% 50%)',
                        }
                    );
                });
            };
        });
    }
    
    handleAnimate();
}