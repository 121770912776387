import Swiper, { Navigation } from 'swiper';

export default function SlidesCarousel(el, {
    carouselHandle,
    controlsHandle,
}) {
    const carousel = el.querySelector(carouselHandle);
    const slides = carousel.querySelectorAll('.swiper-slide');
    const swiperHandle = carousel.querySelector('.swiper');
    
    const controls = el.querySelector(controlsHandle);
    const next = controls.querySelector('.swiper-button-next');
    const prev = controls.querySelector('.swiper-button-prev');

    new Swiper(swiperHandle, { // eslint-disable-line no-new
        modules: [Navigation],
        navigation: {
            nextEl: next,
            prevEl: prev,
        },
        allowTouchMove: false,
        slidesOffsetAfter: 0,
        slidesOffsetBefore: 0,
        slidesPerGroup: 1,
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 600,
    });

    slides.forEach((s) => {
        const video = s.querySelector('video');

        if (video) {
            video.addEventListener('mouseenter', () => {
                video.play();
            });

            video.addEventListener('mouseleave', () => {
                video.pause();
            });
        }
    });
}
